

    import { ref } from 'vue'
    import axios from "axios";
    import {useToast} from "primevue/usetoast";
    import {useConfirm} from "primevue/useconfirm";
    import router from "@/router";
    import {useStore} from "vuex";
    import {DeTaiForm} from "@/models/deTaiForm.models";
    import NghienCuuKhoaHocRepository from "@/services/NghienCuuKhoaHocRepository";
    import {DeTaiCreate} from "@/models/deTaiCreate";
    import {FileDetail} from "@/models/fileDetail.model";
    import {UserDetail} from "@/models/userDetail";
    import AuthRepository from "@/services/AuthRepository";
    import {ChuyenNganh} from "@/models/chuyenNganh";
    import {LinhVuc} from "@/models/linhVuc";
    import {CapDo} from "@/models/capDo";

    export default {

        setup(){

            const downLoad = ref(false);
            const loadingBar = ref(false);
            const toast = useToast();
            const store = useStore();
            const deTai = ref({} as DeTaiForm);
            const deTaiCreate = ref({} as DeTaiCreate);
            let listFileName = ref([] as FileDetail[]);
            const listLink = ref([] as string[]);
            const listUser = ref([] as UserDetail[]);
            const listChuyenNganh = ref([] as ChuyenNganh[]);
            const listLinhVuc = ref([] as LinhVuc[]);
            const idUser1 = ref(0);
            const idUser2 = ref(0);
            const idUser3 = ref(0);
            const linhVuc = ref("");
            const chuyenNganh = ref("");
            const listCapDo = ref([] as CapDo[]);

            const confirm = useConfirm();
            const confirmSuccess = () => {
                confirm.require({
                    message: 'Thêm chỉ đề tài mới thành công',
                    header: 'Xác nhận',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Thêm đề tài mới mới',
                    //rejectLabel: 'Gởi tiếp',
                    accept: () => {
                        //reset();
                    },
                    reject: () => {
                    }
                });
            };
          AuthRepository.getListUser()
              .then((response) => {
                listUser.value = response.data;
              })
              .catch();

          NghienCuuKhoaHocRepository.listChuyenNganh()
              .then((response) => {
                listChuyenNganh.value = response.data;
              })
              .catch();

          NghienCuuKhoaHocRepository.listCapDo()
              .then((response) => {
                listCapDo.value = response.data;
              })
              .catch();

          NghienCuuKhoaHocRepository.listLinhVuc()
              .then((response) => {
                listLinhVuc.value = response.data;
              })
              .catch();

          const fileChange = (fileList) => {
            console.log("###### fileChange");
            var leng = fileList.length;
            for (var i = 0; i < leng; i++)
            {
              var filetemp = ref({} as FileDetail);
              filetemp.value.nameFile = fileList[i].name;
              filetemp.value.value = fileList[i];
              listFileName.value.push(filetemp.value);
            }
            console.log("###### fileChange fileList: " + JSON.stringify(fileList.value));
            console.log("###### fileChange listFileName: " + JSON.stringify(listFileName.value));
          };
          const upload = () => {
            {
              listLink.value = [];
              var files = new FormData();
              listFileName.value.forEach(x =>
              {
                console.log("###### fileChange add x.nameFile: " + JSON.stringify(x.nameFile));
                files.append("formFiles", x.value, x.nameFile);
              });
              console.log("###### fileChange upload files: " + JSON.stringify(files.values()));
              loadingBar.value = true;
              //loadingBar.value = true;
              //downLoad.value = false;
              //console.log("###########@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$ upload: ");
              axios.post(`/api/file/Upload`, files,
                  {
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'multipart/form-data'
                    }
                  }).then(response => {
                const list = ref([] as string[]);
                list.value = response.data.result;
                deTaiCreate.value.tapTin = "";
                list.value.forEach(x => {
                  deTaiCreate.value.tapTin += x + ">>>>";
                })
                deTaiCreate.value.tinhTrang = 1;
                if(idUser1.value > 0)
                {
                  deTaiCreate.value.idNguoiThucHien1 = idUser1.value;
                  listUser.value.forEach(x => {
                    if(x.id == idUser1.value)
                      deTaiCreate.value.nguoiThucHien1 = x.fullname;
                  });
                };
                if(idUser2.value > 0)
                {
                  deTaiCreate.value.idNguoiThucHien2 = idUser2.value;
                  listUser.value.forEach(x => {
                    if(x.id == idUser2.value)
                      deTaiCreate.value.nguoiThucHien2 = x.fullname;
                  });
                };
                if(idUser3.value > 0)
                {
                  deTaiCreate.value.idNguoiThucHien3 = idUser3.value;
                  listUser.value.forEach(x => {
                    if(x.id == idUser3.value)
                      deTaiCreate.value.nguoiThucHien3 = x.fullname;
                  });
                };


                NghienCuuKhoaHocRepository.createDeTai(deTaiCreate.value)
                    .then((response) => {
                      loadingBar.value = false;
                     confirmSuccess();
                    })
                    .catch(error => {
                      loadingBar.value = false;
                      toast.add({
                        severity: 'error',
                        summary: 'Lỗi',
                        detail:error.response.data,
                        life: 2500
                      })
                    })
                    .finally(
                        //function(){
                        //setTimeout(loadList, 1 * 1000);}
                    );
              })
                  .catch(err => {
                    toast.add({
                      severity: 'error',
                      summary: 'Lỗi',
                      detail:err.response.data,
                      life: 2500
                    })});
            }
          };

          const valid = () => {
            return deTaiCreate.value.tenDeTai && deTaiCreate.value.noiDungDeTai && deTaiCreate.value.linhVuc && deTaiCreate.value.chuyenNganh
                && deTaiCreate.value.capDoDeTai && deTaiCreate.value.maSoDeTai && (idUser1.value > 0);
          }
            return {
                downLoad,
                loadingBar,
                deTaiCreate,
                listFileName,
                upload,
                fileChange,
                valid,
                listLinhVuc,
                listChuyenNganh,
                listUser,
                idUser1,
                idUser2,
                idUser3,
                linhVuc,
                chuyenNganh,
              listCapDo,
            }
        }
    }

