

    import { ref } from 'vue'
    import axios from "axios";
    import {useToast} from "primevue/usetoast";
    import {useConfirm} from "primevue/useconfirm";
    import router from "@/router";
    import {useStore} from "vuex";
    import {DeTaiForm} from "@/models/deTaiForm.models";
    import NghienCuuKhoaHocRepository from "@/services/NghienCuuKhoaHocRepository";
    import {DeTaiCreate} from "@/models/deTaiCreate";
    import {FileDetail} from "@/models/fileDetail.model";
    import {DeTai} from "@/models/deTai";
    import {Link} from "@/models/link";
    import {NhanXetCreate} from "@/models/nhanXetCreate";

    export default {
      props: {
        id: Number,
      },
      setup(props){

            const downLoad = ref(false);
            const loadingBar = ref(false);
            const toast = useToast();
            const store = useStore();
            const deTaiCreate = ref({} as DeTaiCreate);
            let listFileName = ref([] as FileDetail[]);
            const listLink = ref([] as string[]);
            const deTai = ref({} as DeTai);
            const nhanXet = ref({} as NhanXetCreate);
            const tieude = ref("");

          NghienCuuKhoaHocRepository.deTaiById(props.id)
              .then((response) => {
                deTai.value = response.data;
                tieude.value = "Duyệt đề tài lần " + deTai.value.tinhTrang;
                if((deTai.value.tapTin == null)||(deTai.value.tapTin == ""))
                {
                }
                else {
                  var str = deTai.value.tapTin.split(">>>>");
                  str.forEach(x => {
                    if(x.length > 0)
                    {
                      var filetemp = ref({} as FileDetail);
                      filetemp.value.nameFile = x.split("/")[2];
                      filetemp.value.value = x;
                      listFileName.value.push(filetemp.value);
                    }
                  })
                }
              })
              .catch(error => {
              })
              .finally(
                  //function(){
                  //setTimeout(loadList, 1 * 1000);}
              );


            const confirm = useConfirm();
            const confirmSuccess = () => {
                confirm.require({
                    message: 'Thêm chỉ đề tài mới thành công',
                    header: 'Xác nhận',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Thêm đề tài mới mới',
                    //rejectLabel: 'Gởi tiếp',
                    accept: () => {
                        //reset();
                    },
                    reject: () => {
                    }
                });
            };

          const nhanXetDeTai = () => {
            nhanXet.value.lanDuyetDeTai = 1;
            nhanXet.value.idDeTai = deTai.value.id;
            NghienCuuKhoaHocRepository.createNhanXet(nhanXet.value)
                .then((response) => {
                })
                .catch(error => {
                })
                .finally();
          }
          const duyetDeTai = () => {
            console.log("################# duyetDeTai");
            if(deTai.value.tinhTrang == null)
            {
              deTai.value.tinhTrang = 2;
            }
            else
              deTai.value.tinhTrang = deTai.value.tinhTrang + 1;
            deTai.value.thoiGianDuyet = new Date();
            NghienCuuKhoaHocRepository.updateDeTai(deTai.value)
                .then((response) => {
                  loadingBar.value = false;
                  confirmSuccess();
                  toast.add({
                    severity: 'success',
                    summary: 'Thành công',
                    detail:'Duyệt đề tài thành công',
                    life: 2500
                  })
                })
                .catch(error => {
                  loadingBar.value = false;
                })
                .finally(
                    //function(){
                    //setTimeout(loadList, 1 * 1000);}
                );
            nhanXetDeTai();
          }

        const huyDeTai = () => {
            console.log("################# huyDeTai");
          deTai.value.duyetLan1 = "từ chối";
          NghienCuuKhoaHocRepository.updateDeTai(deTai.value)
              .then((response) => {
                loadingBar.value = false;
                confirmSuccess();
                toast.add({
                  severity: 'success',
                  summary: 'Thành công',
                  detail:'Từ chối đề tài thành công',
                  life: 2500
                })
              })
              .catch(error => {
                loadingBar.value = false;
              })
              .finally(
              );
          nhanXetDeTai();
        }
        const downLoadFile = (file) => {
          const links = ref({} as Link);
          links.value.linkFile = file;
          axios({
            url: '../api/nghiencuu/download/',
            method: 'GET',
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json'
            },
            params: { linkFile: links.value.linkFile },
          })
              .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', links.value.linkFile.split("/")[2]);
            document.body.appendChild(link);
            link.click();


          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
        };
        const valid = () => {
          return nhanXet.value.noiDung;
        }

            return {
                downLoad,
                loadingBar,
                deTaiCreate,
                listFileName,
              deTai,
              downLoadFile,
              nhanXet,
              duyetDeTai,
              huyDeTai,
              valid,
              tieude,
            }
        }
    }

