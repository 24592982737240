import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import ThemDeTai from "@/pages/ThemDeTai.vue";
import DsDeTai1 from "@/pages/DanhSachDeTai1.vue";
import DsDeTai2 from "@/pages/DanhSachDeTai2.vue";
import DsDeTai3 from "@/pages/DanhSachDeTai3.vue";
import DuyetDeTai from "@/pages/DuyetDeTai.vue";
import DeTaiTuChoi from "@/pages/DeTaiTuChoi.vue";
import DanhSachDeTaiTuChoi from "@/pages/DanhSachDeTaiTuChoi.vue";
import DanhSachDeTai from "@/pages/DanhSachDeTai.vue";
import ChiTietDeTai from "@/pages/ChiTietDeTai.vue";
import ThemVanBan from "@/pages/ThemVanBan.vue";
import DanhSachVanBan from "@/pages/DanhSachVanBan.vue";
import ChiTietVanBan from "@/pages/ChiTietVanBan.vue";
import ThemHoiNghi from "@/pages/ThemHoiNghi.vue";
import DanhSachHoiNghi from "@/pages/DanhSachHoiNghi.vue";
import ChiTietHoiNghi from "@/pages/ChiTietHoiNghi.vue";
import UserList from "@/pages/UserList.vue";
import UserUpdatePage from "@/pages/UserUpdatePage.vue";
import RegisterPage from "@/pages/RegisterPage.vue";
import CauHinhThongSo from "@/pages/CauHinhThongSo.vue";
import cauHinhThongSo from "@/pages/CauHinhThongSo.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/dstaikhoan',
    name: 'dstaikhoan',
    component: UserList,
    props: true
  },
  {
    path: '/capnhattaikhoan/:id',
    name: 'capnhattaikhoan',
    component: UserUpdatePage,
    props: true
  },
  {
    path: '/dangkytaikhoan',
    name: 'dangkytaikhoan',
    component: RegisterPage,
    props: true
  },
  {
    path: '/themdetai',
    name: 'themdetai',
    component: ThemDeTai,
    props: true
  },
  {
    path: '/dsdetai1',
    name: 'dsdetai1',
    component: DsDeTai1,
    props: true
  },
  {
    path: '/dsdetai2',
    name: 'dsdetai2',
    component: DsDeTai2,
    props: true
  },
  {
    path: '/dsdetai3',
    name: 'dsdetai3',
    component: DsDeTai3,
    props: true
  },
  {
    path: '/duyetdetai/:id',
    name: 'duyetdetai',
    component: DuyetDeTai,
    props: true
  },
  {
    path: '/detaituchoi/:id',
    name: 'detaituchoi',
    component: DeTaiTuChoi,
    props: true
  },
  {
    path: '/danhsachdetaituchoi',
    name: 'danhsachdetaituchoi',
    component: DanhSachDeTaiTuChoi,
    props: true
  },
  {
    path: '/danhsachdetai',
    name: 'danhsachdetai',
    component: DanhSachDeTai,
    props: true
  },
  {
    path: '/chitietdetai/:id',
    name: 'chitietdetai',
    component: ChiTietDeTai,
    props: true
  },
  {
    path: '/themvanban',
    name: 'themvanban',
    component: ThemVanBan,
    props: true
  },
  {
    path: '/danhsachvanban',
    name: 'danhsachvanban',
    component: DanhSachVanBan,
    props: true
  },
  {
    path: '/chitietvanban/:id',
    name: 'chitietvanban',
    component: ChiTietVanBan,
    props: true
  },
  {
    path: '/themhoinghi',
    name: 'themhoinghi',
    component: ThemHoiNghi,
    props: true
  },
  {
    path: '/danhsachhoinghi',
    name: 'danhsachhoinghi',
    component: DanhSachHoiNghi,
    props: true
  },
  {
    path: '/chitiethoinghi/:id',
    name: 'chitiethoinghi',
    component: ChiTietHoiNghi,
    props: true
  },
  {
    path: '/cauhinhthongso',
    name: 'cauhinhthongso',
    component: cauHinhThongSo,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
