

    import { ref } from 'vue'
    import axios from "axios";
    import {useToast} from "primevue/usetoast";
    import {useConfirm} from "primevue/useconfirm";
    import router from "@/router";
    import {useStore} from "vuex";
    import {DeTaiForm} from "@/models/deTaiForm.models";
    import NghienCuuKhoaHocRepository from "@/services/NghienCuuKhoaHocRepository";
    import {DeTaiCreate} from "@/models/deTaiCreate";
    import {FileDetail} from "@/models/fileDetail.model";
    import {VanBanCreate} from "@/models/vanBanCreate";
    import {HoiNghiCreate} from "@/models/hoiNghiCreate";

    export default {

        setup(){

            const downLoad = ref(false);
            const loadingBar = ref(false);
            const toast = useToast();
            const store = useStore();
            const hoiNghiCreate = ref({} as HoiNghiCreate);
            let listFileName = ref([] as FileDetail[]);
            const listLink = ref([] as string[]);

            const confirm = useConfirm();
            const confirmSuccess = () => {
                confirm.require({
                    message: 'Thêm chỉ đề tài mới thành công',
                    header: 'Xác nhận',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Thêm đề tài mới mới',
                    //rejectLabel: 'Gởi tiếp',
                    accept: () => {
                        //reset();
                    },
                    reject: () => {
                    }
                });
            };
          const fileChange = (fileList) => {
            var leng = fileList.length;
            for (var i = 0; i < leng; i++)
            {
              var filetemp = ref({} as FileDetail);
              filetemp.value.nameFile = fileList[i].name;
              filetemp.value.value = fileList[i];
              listFileName.value.push(filetemp.value);
            }
          };
          const upload = () => {
            {
              listLink.value = [];
              var files = new FormData();
              listFileName.value.forEach(x =>
              {
                files.append("formFiles", x.value, x.nameFile);
              });
              loadingBar.value = true;
              axios.post(`/api/file/Upload`, files,
                  {
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'multipart/form-data'
                    }
                  }).then(response => {
                const list = ref([] as string[]);
                list.value = response.data.result;
                hoiNghiCreate.value.tapTin = "";
                list.value.forEach(x => {
                  hoiNghiCreate.value.tapTin += x + ">>>>";
                })

                NghienCuuKhoaHocRepository.createHoiNghi(hoiNghiCreate.value)
                    .then((response) => {
                      loadingBar.value = false;
                     confirmSuccess();
                    })
                    .catch(error => {
                      loadingBar.value = false;
                    })
                    .finally(
                        //function(){
                        //setTimeout(loadList, 1 * 1000);}
                    );
              })
                  .catch(err => {
                    toast.add({
                      severity: 'error',
                      summary: 'Lỗi',
                      detail:err.response.data,
                      life: 2500
                    })});
            }
          };
          const valid = () => {
            return hoiNghiCreate.value.tenHoiNghi && hoiNghiCreate.value.noiDungHoiNghi;
          }

            return {
              downLoad,
              loadingBar,
              hoiNghiCreate,
              listFileName,
              upload,
              fileChange,
              valid,
            }
        }
    }

