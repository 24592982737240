

    import { ref } from 'vue'
    import axios from "axios";
    import {useToast} from "primevue/usetoast";
    import {useConfirm} from "primevue/useconfirm";
    import router from "@/router";
    import {useStore} from "vuex";
    import {DeTaiForm} from "@/models/deTaiForm.models";
    import NghienCuuKhoaHocRepository from "@/services/NghienCuuKhoaHocRepository";
    import {DeTaiCreate} from "@/models/deTaiCreate";
    import {FileDetail} from "@/models/fileDetail.model";
    import {DeTai} from "@/models/deTai";
    import {Link} from "@/models/link";
    import {NhanXetCreate} from "@/models/nhanXetCreate";
    import {NhanXetDetail} from "@/models/nhanXetDetail";
    import {VanBan} from "@/models/vanBan";
    import {HoiNghi} from "@/models/hoiNghi";

    export default {
      props: {
        id: Number,
      },
      setup(props){

            const downLoad = ref(false);
            const loadingBar = ref(false);
            const toast = useToast();
            const store = useStore();
            let listFileName = ref([] as FileDetail[]);
            const listLink = ref([] as string[]);
            const hoiNghi = ref({} as HoiNghi);

          NghienCuuKhoaHocRepository.hoiNghiById(props.id)
              .then((response) => {
                hoiNghi.value = response.data;
                if((hoiNghi.value.tapTin == null)||(hoiNghi.value.tapTin == ""))
                {
                }
                else {
                  var str = hoiNghi.value.tapTin.split(">>>>");
                  str.forEach(x => {
                    if(x.length > 0)
                    {
                      var filetemp = ref({} as FileDetail);
                      filetemp.value.nameFile = x.split("/")[2];
                      filetemp.value.value = x;
                      listFileName.value.push(filetemp.value);
                    }
                  })
                }
              })
              .catch(error => {
              })
              .finally(
                  //function(){
                  //setTimeout(loadList, 1 * 1000);}
              );
        const downLoadFile = (file) => {
          const links = ref({} as Link);
          links.value.linkFile = file;
          axios({
            url: '../api/nghiencuu/download/',
            method: 'GET',
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json'
            },
            params: { linkFile: links.value.linkFile },
          })
              .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', links.value.linkFile.split("/")[2]);
            document.body.appendChild(link);
            link.click();


          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
        };
            return {
                downLoad,
                loadingBar,
                listFileName,
              hoiNghi,
              downLoadFile,
            }
        }
    }

