
import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {UserDetail} from "@/models/userDetail";
import {ChuyenNganh} from "@/models/chuyenNganh";
import NghienCuuKhoaHocRepository from "@/services/NghienCuuKhoaHocRepository";
import {ChuyenNganhCreate} from "@/models/chuyenNganhCreate";
import {LinhVuc} from "@/models/linhVuc";
import {LinhVucCreate} from "@/models/linhVucCreate";
import {CapDo} from "@/models/capDo";
import {CapDoCreate} from "@/models/capDoCreate";

export default {
  setup() {
    const toast = useToast();
    const store = useStore();
    const dsChuyenNganh = ref([] as ChuyenNganh[]);
    const chuyenNganhCreate = ref({} as ChuyenNganhCreate);
    const dsLinhVuc = ref([] as LinhVuc[]);
    const linhVucCreate = ref({} as LinhVucCreate);
    const dsCapDo = ref([] as CapDo[]);
    const capDoCreate = ref({} as CapDoCreate);

    if(!(store.state.permission == 'admin')){
      router.push({
        name: 'home'
      });
    }

    // AuthRepository.getListUser()
    //     .then((response) => {
    //       list.value = response.data;
    //     })
    //     .catch();

    const loadChuyenNganh = () => {
      NghienCuuKhoaHocRepository.listChuyenNganh()
          .then((response) => {
            dsChuyenNganh.value = response.data;
          })
          .catch();
    }

    loadChuyenNganh();

    const createChuyenNganh = () => {
      NghienCuuKhoaHocRepository.createChuyenNganh(chuyenNganhCreate.value)
          .then((response) => {
            chuyenNganhCreate.value.tenChuyenNganh = "";
            loadChuyenNganh();
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm chuyên ngành thành công',
              life: 2500
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    }

    const deleteChuyenNganh = (id: number) => {
      NghienCuuKhoaHocRepository.deleteChuyenNganh(id)
          .then((response) => {
            loadChuyenNganh();
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Xóa chuyên ngành thành công',
              life: 2500
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
      chuyenNganhCreate.value.tenChuyenNganh = "";
      loadChuyenNganh();
    }

    const loadLinhVuc = () => {
      NghienCuuKhoaHocRepository.listLinhVuc()
          .then((response) => {
            dsLinhVuc.value = response.data;
          })
          .catch();
    }

    loadLinhVuc();

    const createLinhVuc = () => {
      NghienCuuKhoaHocRepository.createLinhVuc(linhVucCreate.value)
          .then((response) => {
            linhVucCreate.value.tenLinhVuc = "";
            loadLinhVuc();
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm lĩnh vực thành công',
              life: 2500
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    }

    const deleteLinhVuc = (id: number) => {
      NghienCuuKhoaHocRepository.deleteLinhVuc(id)
          .then((response) => {
            loadLinhVuc();
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Xóa lĩnh vực thành công',
              life: 2500
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
      linhVucCreate.value.tenLinhVuc = "";
    }

    const loadCapDo = () => {
      NghienCuuKhoaHocRepository.listCapDo()
          .then((response) => {
            dsCapDo.value = response.data;
          })
          .catch();
    }

    loadCapDo();

    const createCapDo = () => {
      NghienCuuKhoaHocRepository.createCapDo(capDoCreate.value)
          .then((response) => {
            capDoCreate.value.capDoDeTai = "";
            loadCapDo();
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm cấp độ thành công',
              life: 2500
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    }

    const deleteCapDo = (id: number) => {
      NghienCuuKhoaHocRepository.deleteCapDo(id)
          .then((response) => {
            loadCapDo();
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Xóa cấp độ thành công',
              life: 2500
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
      capDoCreate.value.capDoDeTai = "";
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });
    const filters2 = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });
    const filters3 = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      filters,
      filters2,
      filters3,
      clearFilter,
      initFilters,
      dsChuyenNganh,
      createChuyenNganh,
      deleteChuyenNganh,
      chuyenNganhCreate,
      dsLinhVuc,
      createLinhVuc,
      deleteLinhVuc,
      linhVucCreate,
      dsCapDo,
      createCapDo,
      deleteCapDo,
      capDoCreate,
    }
  }
}
