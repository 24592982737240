
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const defaultMenu = [
    ];
    const adminMenu = ref([
      {
        label: 'Đề tài',
        icon: 'pi pi-fw pi-file',
        items: [{
          label: 'Đăng ký đề tài',
          icon: 'pi pi-fw pi-plus',
          url: "/themdetai"
        },
        {
          label: 'Danh sách đề tài(lần 1)',
          icon: 'pi pi-fw pi-list',
          url: "/dsdetai1"
        },
        {
          label: 'Danh sách đề tài(lần 2)',
          icon: 'pi pi-fw pi-list',
          url: "/dsdetai2"
        },
        {
          label: 'Danh sách đề tài(lần 3)',
          icon: 'pi pi-fw pi-list',
          url: "/dsdetai3"
        },
        {
          label: 'Danh sách đề tài từ chối',
          icon: 'pi pi-fw pi-ban',
          url: "/danhsachdetaituchoi"
        },
          {
            label: 'Cấu hình thông số',
            icon: 'pi pi-fw pi-cog',
            url: "/cauhinhthongso"
          }
        ]
      },
      {
        label: 'Thư viện đề tài',
        icon: 'pi pi-fw pi-briefcase',
        items: [{
          label: 'Danh sách đề tài',
          icon: 'pi pi-fw pi-align-justify',
          url: "/danhsachdetai"
        }
        ]
      },
      {
        label: 'Thư viện văn bản',
        icon: 'pi pi-fw pi-folder',
        items: [{
          label: 'Thêm văn bản',
          icon: 'pi pi-fw pi-plus',
          url: "/themvanban"
        },
          {
            label: 'Danh sách văn bản',
            icon: 'pi pi-fw pi-list',
            url: "/danhsachvanban"
          }
        ]
      },
      {
        label: 'Hội nghị KHKT',
        icon: 'pi pi-fw pi-desktop',
        items: [{
          label: 'Thêm hội nghị',
          icon: 'pi pi-fw pi-plus',
          url: "/themhoinghi"
        },
          {
            label: 'Danh sách hội nghị',
            icon: 'pi pi-fw pi-list',
            url: "/danhsachhoinghi"
          }
        ]
      },
    ]);
    const userMenu = ref([
      {
        label: 'Đề tài',
        icon: 'pi pi-fw pi-file',
        items: [{
          label: 'Đăng ký đề tài',
          icon: 'pi pi-fw pi-plus',
          url: "/themdetai"
        },
          {
            label: 'Danh sách đề tài từ chối',
            icon: 'pi pi-fw pi-ban',
            url: "/danhsachdetaituchoi"
          }
        ]
      },
      {
        label: 'Thư viện đề tài',
        icon: 'pi pi-fw pi-briefcase',
        items: [{
          label: 'Danh sách đề tài',
          icon: 'pi pi-fw pi-align-justify',
          url: "/danhsachdetai"
        }
        ]
      },
      {
        label: 'Thư viện văn bản',
        icon: 'pi pi-fw pi-folder',
        items: [
          {
            label: 'Danh sách văn bản',
            icon: 'pi pi-fw pi-list',
            url: "/danhsachvanban"
          }
        ]
      },
      {
        label: 'Hội nghị KHKT',
        icon: 'pi pi-fw pi-desktop',
        items: [
          {
            label: 'Danh sách hội nghị',
            icon: 'pi pi-fw pi-list',
            url: "/danhsachhoinghi"
          }
        ]
      }
    ]);
    const employeeMenu = ref([
      {
        label: 'Thư viện đề tài',
        icon: 'pi pi-fw pi-briefcase',
        items: [{
          label: 'Danh sách đề tài',
          icon: 'pi pi-fw pi-align-justify',
          url: "/danhsachdetai"
        }
        ]
      },
      {
        label: 'Thư viện văn bản',
        icon: 'pi pi-fw pi-folder',
        items: [
          {
            label: 'Danh sách văn bản',
            icon: 'pi pi-fw pi-list',
            url: "/danhsachvanban"
          }
        ]
      },
      {
        label: 'Hội nghị KHKT',
        icon: 'pi pi-fw pi-desktop',
        items: [
          {
            label: 'Danh sách hội nghị',
            icon: 'pi pi-fw pi-list',
            url: "/danhsachhoinghi"
          }
        ]
      }
    ]);
    const appMenu = computed(() => {
      // if(store.state.permission == "admin") return adminMenu;
      // else if(store.state.permission == "user") return userMenu;
      // else
      console.log("###########@@@@@@@@@@ check menu store.state.permission: " + store.state.permission);
      if(store.state.permission == "admin")
      {
        console.log("###########@@@@@@@@@@ check menu admin: ");
        return adminMenu.value;
      }
      else if(store.state.permission == "user") {
        console.log("###########@@@@@@@@@@ check menu user: ");
        return userMenu.value;
      }
      else if(store.state.permission == "employee") {
        console.log("###########@@@@@@@@@@ check menu user: ");
        return employeeMenu.value;
      }
      else {
        console.log("###########@@@@@@@@@@ check menu aaaaaa: ");
        return defaultMenu;
      }
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }

    return {
      appMenu,
      logout,
      userMenu
    }
  }
}
