<template>
  <div class="app-container">
    <Toast />
    <transition name="layout-sidebar">
      <div class="layout-sidebar layout-sidebar-dark" >
        <TopNavBar :isLoggedIn="isLoggedIn"/>
      </div>
    </transition>
    <div class="layout-main">

      <router-view/>
    </div>
<!--    <div>-->
<!--      <img alt="logo" src="kclco.png" height="30" class="">-->
<!--      <b height="30" style="alignment: center; color: dimgray">© Copyright 2021 - 2022 – Công ty TNHH Phát Triển Công Nghệ Và Thương Mại Dịch Vụ KCL</b>-->
<!--    </div>-->
  </div>

</template>

<style lang="scss">
.layout-main {
  //padding: 10px 0 0 0;
  height: 100%;
  overflow-y: scroll;
  float: left;
  margin-left: 250px;
}
.layout-sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  transition: transform;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
@import 'assets/style.css';
</style>
<script>
import TopNavBar from "@/components/TopNavBar";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  components: {TopNavBar},
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);

    return {
      isLoggedIn
    }
  }
}
</script>
