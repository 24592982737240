

    import { ref } from 'vue'
    import axios from "axios";
    import {useToast} from "primevue/usetoast";
    import {useConfirm} from "primevue/useconfirm";
    import router from "@/router";
    import {useStore} from "vuex";
    import {DeTaiForm} from "@/models/deTaiForm.models";
    import NghienCuuKhoaHocRepository from "@/services/NghienCuuKhoaHocRepository";
    import {DeTaiCreate} from "@/models/deTaiCreate";
    import {FileDetail} from "@/models/fileDetail.model";
    import {DeTai} from "@/models/deTai";
    import {Link} from "@/models/link";
    import {NhanXetCreate} from "@/models/nhanXetCreate";
    import {NhanXetDetail} from "@/models/nhanXetDetail";

    export default {
      props: {
        id: Number,
      },
      setup(props){

            const downLoad = ref(false);
            const loadingBar = ref(false);
            const toast = useToast();
            const store = useStore();
            let listFileName = ref([] as FileDetail[]);
            let listFileNameForKHUD = ref([] as FileDetail[]);
            let listFile = ref([] as FileDetail[]);
            const listLink = ref([] as string[]);
            const deTai = ref({} as DeTai);
            const deTaiTemp = ref({} as DeTai);
            const tieude = ref("");
            const nhanXet = ref([] as NhanXetDetail[]);

          NghienCuuKhoaHocRepository.deTaiById(props.id)
              .then((response) => {
                deTai.value = response.data;
                deTaiTemp.value = response.data;
                console.log("###### deTaiById listFileName: " + JSON.stringify(listFileName.value));
                console.log("###### deTaiById deTai.value.tapTin: " + JSON.stringify(deTai.value.tapTin));
                tieude.value = "Duyệt đề tài lần " + deTai.value.tinhTrang;
                if((deTai.value.tapTin == null)||(deTai.value.tapTin == ""))
                {
                  console.log("###### deTaiById null: ");
                }
                else {
                  var str = deTai.value.tapTin.split(">>>>");
                  str.forEach(x => {
                    if(x.length > 0)
                    {
                      var filetemp = ref({} as FileDetail);
                      filetemp.value.nameFile = x.split("/")[2];
                      filetemp.value.value = x;
                      listFileName.value.push(filetemp.value);
                    }
                  });
                  console.log("###### fileChange listFileName: " + JSON.stringify(listFileName.value));
                };
                if((deTai.value.keHoachUngDung == null)||(deTai.value.keHoachUngDung == ""))
                {
                  console.log("###### deTaiById11111 null: ");
                }
                else {
                  var str1 = deTai.value.keHoachUngDung.split(">>>>");
                  str1.forEach(x => {
                    if(x.length > 0)
                    {
                      var filetemp = ref({} as FileDetail);
                      filetemp.value.nameFile = x.split("/")[2];
                      filetemp.value.value = x;
                      listFileNameForKHUD.value.push(filetemp.value);
                    }
                  });
                  console.log("###### fileChange listFileNameForKHUD: " + JSON.stringify(listFileNameForKHUD.value));
                }
              })
              .catch(error => {
              })
              .finally(
                  //function(){
                  //setTimeout(loadList, 1 * 1000);}
              );


            const confirm = useConfirm();
            const confirmSuccess = () => {
                confirm.require({
                    message: 'Thêm chỉ đề tài mới thành công',
                    header: 'Xác nhận',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Thêm đề tài mới mới',
                    //rejectLabel: 'Gởi tiếp',
                    accept: () => {
                        //reset();
                    },
                    reject: () => {
                    }
                });
            };

        NghienCuuKhoaHocRepository.listNhanXetById(props.id)
            .then((response) => {
              nhanXet.value = response.data;
            })
            .catch(error => {
            })
            .finally(
                //function(){
                //setTimeout(loadList, 1 * 1000);}
            );
        const downLoadFile = (file) => {
          const links = ref({} as Link);
          links.value.linkFile = file;
          axios({
            url: '../api/nghiencuu/download/',
            method: 'GET',
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json'
            },
            params: { linkFile: links.value.linkFile },
          })
              .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', links.value.linkFile.split("/")[2]);
            document.body.appendChild(link);
            link.click();


          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
        };
        const fileChange = (fileList) => {
          console.log("###### fileChange");
          var leng = fileList.length;
          for (var i = 0; i < leng; i++)
          {
            var filetemp = ref({} as FileDetail);
            filetemp.value.nameFile = fileList[i].name;
            filetemp.value.value = fileList[i];
            listFile.value.push(filetemp.value);
          }
          console.log("###### fileChange fileList: " + JSON.stringify(fileList.value));
          console.log("###### fileChange listFileName: " + JSON.stringify(listFile.value));
        };
        const upload = () =>
        {
          listLink.value = [];
          var files = new FormData();
          listFile.value.forEach(x =>
          {
            console.log("###### fileChange add x.nameFile: " + JSON.stringify(x.nameFile));
            files.append("formFiles", x.value, x.nameFile);
          });
          console.log("###### fileChange upload files: " + JSON.stringify(files.values()));
          loadingBar.value = true;
          axios.post(`/api/file/Upload`, files,
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
            const list = ref([] as string[]);
            list.value = response.data.result;
            deTaiTemp.value.keHoachUngDung = "";
            list.value.forEach(x => {
              deTaiTemp.value.keHoachUngDung += x + ">>>>";
            });
            capNhapDeTai();
          })
        };
        const deleteFile = (name: string) =>
        {
          listFile.value.findIndex(x => x.nameFile == name);
          listFile.value.splice(listFile.value.findIndex(x => x.nameFile == name), 1);
        };
        const capNhapDeTai = () => {
          console.log("################# capNhapDeTai");
          NghienCuuKhoaHocRepository.updateDeTai(deTaiTemp.value)
              .then((response) => {
                loadingBar.value = false;
                confirmSuccess();
              })
              .catch(error => {
                loadingBar.value = false;
              })
              .finally(
                  //function(){
                  //setTimeout(loadList, 1 * 1000);}
              );
        }
            return {
              downLoad,
              loadingBar,
              listFileName,
              listFile,
              deTai,
              downLoadFile,
              nhanXet,
              tieude,
              fileChange,
              upload,
              capNhapDeTai,
              deleteFile,
              listFileNameForKHUD,
            }
        }
    }

