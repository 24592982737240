
import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {UserDetail} from "@/models/userDetail";
import {DeTai} from "@/models/deTai";
import NghienCuuKhoaHocRepository from "@/services/NghienCuuKhoaHocRepository";

export default {
  setup() {
    const toast = useToast();
    const list = ref([] as UserDetail[]);
    const store = useStore();
    const dsDeTai = ref([] as DeTai[]);

    NghienCuuKhoaHocRepository.listDeTai(4)
        .then((response) => {
          dsDeTai.value = response.data;
        })
        .catch();



    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      dsDeTai,
    }
  }
}
