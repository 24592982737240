import Repository from './Repository';
import {AxiosResponse} from "axios";
import {DeTaiCreate} from "../models/deTaiCreate";
import {DeTai} from "@/models/deTai";
import {NhanXetCreate} from "@/models/nhanXetCreate";
import {VanBanCreate} from "@/models/vanBanCreate";
import {HoiNghiCreate} from "@/models/hoiNghiCreate";
import {ChuyenNganhCreate} from "@/models/chuyenNganhCreate";
import {LinhVucCreate} from "@/models/linhVucCreate";
import {CapDoCreate} from "@/models/capDoCreate";

const resource = '/nghiencuu';

export default new class{
    createDeTai(deTaiCreate: DeTaiCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/dangKyDeTai`, deTaiCreate);
    }
    updateDeTai(deTai: DeTai): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/capNhatDeTai`, deTai);
    }
    listDeTai(a: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/danhSachDeTai/${a}`);
    }
    listDeTaiTuChoi(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/danhSachDeTaiTuChoi`);
    }
    deTaiById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/detai/${id}`);
    }
    createNhanXet(nhanXetCreate: NhanXetCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/nhanxet`, nhanXetCreate);
    }
    listNhanXetById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/nhanxet/${id}`);
    }
    createVanBan(vanBanCreate: VanBanCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/dangKyVanBan`, vanBanCreate);
    }
    listVanBan(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/danhSachVanBan`);
    }
    vanBanById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/vanban/${id}`);
    }
    createHoiNghi(hoiNghiCreate: HoiNghiCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/dangKyHoiNghi`, hoiNghiCreate);
    }
    listHoiNghi(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/danhSachHoiNghi`);
    }
    hoiNghiById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/HoiNghi/${id}`);
    }
    createChuyenNganh(chuyenNganhCreate: ChuyenNganhCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/ChuyenNganh`, chuyenNganhCreate);
    }
    deleteChuyenNganh(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/XoaChuyenNganh/${id}`);
    }
    listChuyenNganh(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/DsChuyenNganh`);
    }
    createLinhVuc(linhVucCreate: LinhVucCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/LinhVuc`, linhVucCreate);
    }
    deleteLinhVuc(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/XoaLinhVuc/${id}`);
    }
    listLinhVuc(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/DsLinhVuc`);
    }
    createCapDo(capDoCreate: CapDoCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/Capdo`, capDoCreate);
    }
    deleteCapDo(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/XoaCapDo/${id}`);
    }
    listCapDo(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/DsCapDo`);
    }
}
